<!--微信授权-->
<template>
    <div class="WeChat" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="授权状态">
                            <el-select v-model="form.status">
                                <el-option value label="请选择" />
                                <el-option label="未授权" value="0" />
                                <el-option label="已授权" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.finance.weChat.open')"
                >查询
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-dialog title="微信授权" :visible.sync="dialogTableWcEmpower">
            <el-form size="small" label-width="120px" :model="WcEmpower" :rules="WcEmpowerFormRules" ref="WcEmpower">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="机构名称">
                            <el-input v-model="WcEmpower.deptName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="子公众账号ID">
                            <el-input v-model="WcEmpower.subAppId" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="子商户号" prop="subMchId">
                            <el-input v-model="WcEmpower.subMchId" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleEmpower" size="small">确定</el-button>
                <el-button @click="dialogTableWcEmpower = false" size="small">取消</el-button>
            </div>
        </el-dialog>

        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="机构名称" width="240" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="子商户号" width="240" prop="subMchId" v-if="showColumn('subMchId')" />
                <el-table-column label="状态" width="120" prop="status" v-if="showColumn('status')" key="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status ? '已授权' : '未授权' }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作"
                    width="180"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleUpEmpower(scope.row)"
                            v-if="hasPrivilege('menu.finance.weChat.create')"
                            :disabled="scope.row.status == 1"
                            >授权
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="unbound(scope.row)"
                            v-if="hasPrivilege('menu.finance.weChat.unbound')"
                            :disabled="scope.row.status == 0"
                            >解除授权
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import { Message } from 'element-ui';
import EfPagination from 'components/EfPagination';

export default {
    name: 'WeChat',
    components: {
        EfPagination,
        CheckTableShowColumnDialog,
    },
    mixins: [CheckTableShowColumn],

    data() {
        var validsubAppId = (rule, value, callback) => {
            const regNmae = /^\d+$/;
            if (regNmae.test(value)) {
                return callback();
            } else {
                callback(new Error('只能输入数字!'));
            }
        };
        return {
            form: {
                status: '',
                deptCode: '',
                subAppId: '',
                subMchId: '',
                page: 1,
                limit: 20,
            },
            WcEmpower: {
                deptCode: '',
                deptName: '',
                subAppId: '',
                subMchId: '',
            },
            WcEmpowerFormRules: {
                subMchId: [
                    { required: true, message: '请输入子商户号', trigger: 'blur' },
                    { validator: validsubAppId, trigger: 'blur' },
                ],
            },
            total: 0,
            tableData: [],
            dialogTableWcEmpower: false,
            url: {
                page: '/finance/pay/weChatPay/page',
                handleEmpower: '/finance/pay/weChatPay/create',
            },
            depts: [],
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
        });
        this.handleQuery();
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },

        //解除权限绑定
        unbound(row) {
            this.$confirm('确认解除微信授权吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    const _this = this;
                    _this.$http.post('/finance/pay/weChatPay/unbound/' + row.code).then((rst) => {
                        if (rst.data.status == 200) {
                            Message.success({ message: '解除绑定成功' });
                            this.handleQuery();
                        } else {
                            Message.error({ message: '操作失败' + rst.data.status });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解除',
                    });
                });
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, (data) => {
                this.total = data.count;
                this.tableData = data.data;
            });
        },
        handleEmpower() {
            this.$refs.WcEmpower.validate((valid) => {
                if (valid) {
                    const _this = this;
                    UrlUtils.PostRemote(_this, _this.url.handleEmpower, _this.WcEmpower, null, () => {
                        _this.$message.success('授权成功');
                        _this.dialogTableWcEmpower = false;
                        _this.handleQuery();
                    });
                } else {
                    return false;
                }
            });
        },
        handleUpEmpower(row) {
            const _this = this;
            _this.dialogTableWcEmpower = true;
            _this.WcEmpower.deptName = row.deptName;
            _this.WcEmpower.deptCode = row.deptCode;
            _this.WcEmpower.subMchId = '';
            _this.WcEmpower.subAppId = '';
        },
    },
};
</script>

<style scoped>
.WeChat .el-form-item {
    margin-bottom: 0;
}
</style>
